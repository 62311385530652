import React from 'react'
import graph from './graph.svg'
import graphVertical from './graph_vertical.svg'
import Car from './Car'
import car from './car.png'
import carVertical from './car_vertical.png'
import poster from './poster.jpg'
import Layout, { titleCss, CaseStudySpacer } from '~shared/CaseStudyLayout'
import CaseStudyVideo, { Wrapper as VideoWrapper } from '~shared/CaseStudyVideo'
import CaseStudyTitle from '~shared/CaseStudyTitle'
import CaseStudyTextWrapper from '~shared/CaseStudyTextWrapper'
import Graph from '~shared/Graph'
import CaseStudyQuote from '~shared/CaseStudyQuote'
import CaseStudyLinks from '~shared/CaseStudyLinks'
import CaseStudyReadMore from '~shared/CaseStudyReadMore'
import CaseStudyPartnerButton from '~shared/CaseStudyPartnerButton'
import { Link } from 'streamr-ui'
import urls from '~utils/urls'

const MachineWitness = () => (
    <Layout>
        <CaseStudyTitle
            name="Machine&nbsp;Witness"
            summary="Bringing cars into the Internet of Things"
            css={titleCss}
        />
        <VideoWrapper>
            <CaseStudyVideo study="machineWitness" poster={poster} />
        </VideoWrapper>
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                In 2019, as part of the The Trusted IoT Alliance (TIoTA)&apos;s Smart E-Mobility
                Challenge, Streamr partnered with Bosch Software Innovations and Riddle
                &amp;&nbsp;Code, on a pilot project that gathered a range of electric vehicle data
                and shared it openly in&nbsp;real-time.
            </p>
            <p $appear>
                Despite millions of cars on our roads, detailed information from in-vehicle sensors
                is limited and rarely available in real-time. Most of the easily available sources
                of real-time road information come from Google, and is thus entirely siloed and
                centrally controlled. Streamr, Bosch and Riddle & Code trialled an open solution to
                this problem, and were proud to have won the silver medal at the TIoTA awards for
                their groundbreaking&nbsp;pilot.
            </p>
            <p $appear>
                The team connected a prototype Bosch ALEN gateway to a Jaguar I-PACE electric
                vehicle via its CAN bus. A mobile router added connectivity, and enabled a Streamr
                node running on a Raspberry Pi to route the realtime data to a product on The Hub.
                Riddle & Code&apos;s hardware wallet could digitally sign the data, providing a
                unique identity for the&nbsp;vehicle.
            </p>
        </CaseStudySpacer>
        <CaseStudySpacer
            as={CaseStudyQuote}
            author="Peter Busch Product Owner, DLT&nbsp;Mobility&nbsp;at&nbsp;Bosch"
            $appear
            // This will make CaseStudySpacer's tail spacing take over.
            tailSpace={false}
        >
            The Internet of Things, or IoT, is&nbsp;a&nbsp;network comprising billions of
            web-enabled devices. Even now, these devices are everyday companions. As a participant
            in the E-Mobility Challenge of the Trusted IoT Alliance, we are exploring the future
            potential of blockchain and similar distributed ledger technological approaches for the
            future of&nbsp;mobility.
        </CaseStudySpacer>
        <Graph
            src={graph}
            verticalSrc={graphVertical}
            caption="Basic data flow for the Machine Witness pilot"
        >
            <Car src={car} />
            <Car src={carVertical} vertical />
        </Graph>
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                With cars providing data about their environment as part of the Internet
                of&nbsp;Cars, those responsible for maintaining road networks can make better
                decisions on infrastructure investment, drivers can be better informed on how to
                avoid accidents and reduce journey times, and that data can be traded by car
                manufacturers, insurance companies and between other machines to ensure the broadest
                participation in a genuinely open real-time data&nbsp;economy.
            </p>
            <p $appear>
                In this use case, aggregated car data can be sold on The Hub. Highway agencies,
                smart cities, car manufacturers, insurance companies and other drivers could enjoy
                easy data extraction from car sensors via API feeds. Data buyers can also deploy
                autonomous economic agents that can subscribe and make decisions in real-time, such
                as changing speed limits, messaging other drivers or setting up diversions. Cars can
                also measure valuable data unrelated to driving, such as local pollen&nbsp;levels.
            </p>
        </CaseStudySpacer>
        <CaseStudyQuote
            author="Carl Rodrigues, Partnerships Ambassador, Streamr Network&nbsp;AG"
            $appear
        >
            A car can witness all kinds of useful data — traffic bottlenecks, ice, potholes and
            other hazards. Our collaborators are helping us to share and monetise it — Bosch is
            getting the sensor data direct from the EV&apos;s CAN bus, Streamr then unlocks the
            value of the data and shares it with the infrastructure powering the road&nbsp;network.
        </CaseStudyQuote>
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                Streamr has a range of solutions for the automotive industry. If you
                would&nbsp;like&nbsp;to talk to us about how we could help you build something
                similar,&nbsp;please get in touch.
            </p>
            <p $appear>
                <CaseStudyPartnerButton />
            </p>
        </CaseStudySpacer>
        <CaseStudySpacer
            as={CaseStudyReadMore}
            techStack={
                <CaseStudyLinks title="Tech stack">
                    <Link href="https://streamr.network/discover/data-unions/">Data Unions</Link>
                    <Link href={urls.apps.hub} blank>
                        The Hub
                    </Link>
                    <Link href="https://streamr.network/discover/network/">Streamr Network</Link>
                </CaseStudyLinks>
            }
            readMore={
                <CaseStudyLinks title="Learn more">
                    <Link href="https://www.tx.company" blank>
                        Tx.company
                    </Link>
                </CaseStudyLinks>
            }
        />
    </Layout>
)

export default MachineWitness
