import styled, { css } from 'styled-components'
import { MqTablet } from 'streamr-ui/css/consts'

const CarAttrs = () => ({
    alt: '',
})

const Car = styled.img.attrs(CarAttrs)`
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    ${({ vertical }) =>
        vertical
            ? css`
                  display: block;

                  @media ${MqTablet} {
                      display: none;
                  }
              `
            : css`
                  display: none;

                  @media ${MqTablet} {
                      display: block;
                  }
              `}
`

export default Car
