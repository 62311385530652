import React from 'react'
import MachineWitness from '../../components/CaseStudies/MachineWitness'
import Poster from '../../components/CaseStudies/MachineWitness/social.jpg'
import page from '~utils/page'
import Head from '~shared/Head'
import { NETWORK } from '~shared/Nav'

// eslint-disable-next-line import/no-unused-modules
export default page(MachineWitness, {
    highlight: NETWORK,
    headComponent: (
        <Head
            description="Bringing cars into the Internet of Things"
            mediaTitle="Streamr Case Study — Machine Witness"
            posterSize={[1920, 1080]}
            posterUrl={Poster}
            title="Streamr - Machine witness case study | IoT Data Marketplace | Buy &amp; Sell IoT Data"
        />
    ),
})
